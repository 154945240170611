
import React, { useEffect } from "react"

import IntroSpecifications from "./introSpecifications"
import { Buttons } from '../../../components/buttons'

import styled from "styled-components"

import ImageGallery from 'react-image-gallery';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-image-gallery/styles/scss/image-gallery.scss";
import font from "../../../fonts/fonts.module.scss"

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


const boxVariant = {
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, x: -30 }
};

const Box = ({ num, pageContext, texts, imgsAux }) => {

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  switch (num) {
    case 1:
    default:
      return (
        <div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={{ height: '100%' }}
        >
          <div className="container-name ">
            <svg width="14.234" height="73.359" viewBox="0 0 14.234 73.359">
              <path d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"
                transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />
            </svg>
            <h1>{pageContext.name}</h1>
          </div>

          <ImageGallery items={imgsAux} thumbnailPosition='bottom' showPlayButton={false} showBullets={false}
            showFullscreenButton={false}
            onErrorImageURL="https://stand.hcsperformance.pt/wp/wp-content/uploads/2023/02/01_home.webp" />
        </div>
      )
    case 2:
      return (
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={{ height: '100%' }}
        >
          <div className="container-specs p-relative">
            <div className="container-intro-specifications-main scroll-container" >

              <hr className="hr-red" />

              <h2 className={font.oL + ' uppercase title-details'}>{texts.details}</h2>
              <IntroSpecifications marca={pageContext.paMarcas} modelo={pageContext.paModelos} mesano={pageContext.paMesAnos}
                motor={pageContext.paMotors} km={pageContext.paKms} combustivel={pageContext.paCombustivels}
                video={pageContext.paVideos} mobile
              />
            </div>
          </div>
        </motion.div>
      )
    case 3:
      return (
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={{ height: '100%' }}
        >
          <div className="container-specs p-relative" style={{ margintop: '15vh' }}>
            <div className="container-intro-specifications-main scroll-container" >

              <h2 className={font.oL + ' uppercase title-details'}>{texts.caracteristics}</h2>

              
            </div>
          </div>
        </motion.div>
      )
    case 4:
      return (
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={{ height: '100%' }}
        >
          <div className="container-specs p-relative">
            <div className="container-intro-specifications-main scroll-container" >

              <h2 className={font.oL + ' uppercase title-details'}>{texts.notes}</h2>

              <StyledContent>
                <p dangerouslySetInnerHTML={{ __html: pageContext.content }} className={font.oL}></p>
              </StyledContent>
            </div>
          </div>
        </motion.div>
      )
  }

};

const ComponentStandSingleCarTestMobile = ({ bg, pageContext, texts, btnText, btnActionFunction, btnClass }) => {
  const imgsAux = [
    {
      original: pageContext.image?.sourceUrl,
      thumbnail: pageContext.image?.sourceUrl,
    }
  ];

  pageContext.galleryImages.nodes.map((ImageGallery) => (
    imgsAux.push({
      original: ImageGallery.sourceUrl,
      thumbnail: ImageGallery.sourceUrl
    })
  ))


  return (

    <ComponentStandSingleCarMobileStyled bg={bg}>
      <div className="main-div">

        <Box num={1} pageContext={pageContext} texts={texts} imgsAux={imgsAux} />
        <Box num={2} pageContext={pageContext} texts={texts} imgsAux={imgsAux} />
        <Box num={3} pageContext={pageContext} texts={texts} imgsAux={imgsAux} />
        <Box num={4} pageContext={pageContext} texts={texts} imgsAux={imgsAux} />

        <div className={font.oEL + ' container-btn'} >
          <Buttons text={btnText} actionFunction={btnActionFunction} btnClass={btnClass} big />
        </div>

      </div>


    </ComponentStandSingleCarMobileStyled >
  )
}

export default ComponentStandSingleCarTestMobile

const ComponentStandSingleCarMobileStyled = styled.div`
background-repeat: no-repeat;
background-position: top;
background-size: cover;
background-image: ${(props) => props.bg || ''};
background-attachment: fixed;
height: 100%;

.container-btn{
  margin-left: 3vh;
  text-align: left;
  width: 35vw;
  margin-top: 4vh;
  .intro-text{
    line-height: 1 !important;
  }
}

  .main-div{
    padding: 5vw 5vw 5vw 5vw;
  }
  
  .grid{
    display: grid;
  }

  .two-columns{
    grid-template-columns: 50% 50%;
  }

  .container-close{
    width: 2vh;
    position: absolute;
    right: 1vh;
    top: 5vh;
    svg{
      width: 2vh;
      height: 2vh;
      path{
        fill: #f2f2f2;
      }
    }
  }

  .container-specs{
    grid-template-columns: 68vw 21vw;
  }
  .container-form{
    position: fixed;
    top: 2vh;
    height: 100%;
    align-items: center;
    align-content: center;
    align-self: center;
    display: grid;
    vertical-align: middle;
    transition: all 0.2s ease-in-out;
  }

  .p-relative{
    position: relative;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .title-details{
    margin-left: 1%;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    letter-spacing: 0.1em;
  }

  .container-intro-specifications-main{
      padding-left: 5.5vw;
      padding-right: 2vw;
  }

  .p-sticky{
    position: sticky;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

    #form{
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      min-width: 100%;
      padding-top: 4vh;
      padding-bottom: 4vh;
      padding-right: 3vh;
      padding-left: 3vh;
      margin: auto;    
      border-radius: 10px;
    }
  }

  .container-name{
    display: flex;
    text-transform: uppercase;
    margin-bottom: 3vh;
    margin-top: 5vh;
    align-items: center;

    svg{
      position: relative;
      width: 5vh;
      height: 3vh;
      cursor: pointer;
      :not(:first-child){
        margin-top: 3vh
      }
      path{
        fill: #ab2c29 !important;
      }
    }
  }


  .carousel-slider{
    width: 80%;
    margin: auto;
    height: 70vh;
    img{
      height: 70vh;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .carousel-root{
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .carousel .thumb{
    border: 2px solid #333;
    display: block !important;
  }
  .carousel .thumb.selected, .carousel .thumb:hover{
    border: 2px solid #fff;
  }

  .carousel.carousel-slider .control-arrow:hover{
    background: rgba(0,0,0,0) !important;
  }

  .image-gallery{
    width: fit-content;
  }

  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 35vh;
    height: 35vh;
    object-fit: cover;
    width: 100%;
  }

  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 100% !important;
  }

  .image-gallery-thumbnail{
    border: 1px solid transparent;
  }

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{
    border: 1px solid #ab2c29 !important;
  }

  .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: inline;
    margin-right: 0;
    padding: 0;
    width: 20vw !important;
    height: auto;
    margin: 3vw;
  }

  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    width: 2vw;
    height: auto;
  }

  .image-gallery-left-nav .image-gallery-svg:hover, .image-gallery-right-nav .image-gallery-svg:hover, .image-gallery-icon:hover {
    color: #ab2c29 !important;

  }
  .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    max-width: 90vw;
    margin: auto;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
    min-height: 40vh;
    height: 40vh;
  }

  .hr-red{
    margin-top: 3vh;
    margin-bottom: 3vh;
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, rgba(171,44,41,1) 0%, rgba(171,44,41,1) 100%);
  }

`

const StyledContent = styled.div`
  margin-left: 1%;
  p{
    letter-spacing: 2px !important;
    line-height: 1.3 !important;
  }
  div{
    margin-top: 2.5vh;
  }
`
import React from "react"


import styled from "styled-components"
import font from "../../../fonts/fonts.module.scss"

const RedMark = () => {
    return (
        <svg width="3.453" height="17.793" viewBox="0 0 14.234 73.359">
            <path d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"
                transform="translate(0 73.359) rotate(-90)" fill="#ab2c29" />
        </svg>
    )
}

const IntroSpecifications = ({ marca, modelo, mesano, motor, km, combustivel, mobile, video }) => {

    return (
        <IntroSpecificationsCarStyled mobile={mobile}>
            <div className="container-intro-specifications">
                <div className={!mobile ? " grid two-columns " : " grid one-columns "}>
                    <div className="container-info-btns uppercase">
                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>Marca:</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{marca.nodes[0].name}</p>
                        </div>
                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>Modelo:</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{modelo.nodes[0].name}</p>
                        </div>
                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>Mês/Ano::</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{mesano.nodes[0].name}</p>
                        </div>
                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>Potência:</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{motor.nodes[0].name}</p>
                        </div>
                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>KM:</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{km.nodes[0].name}</p>
                        </div>

                        <div className="info-btn">
                            <div className="container-red-mark mr-1 d-inline">
                                <RedMark />
                                <p className={font.oEB + "   d-inline-flex"}>Combustível:</p>
                            </div>
                            <p className={font.oL + "   d-inline-flex"}>{combustivel.nodes[0].name}</p>
                        </div>
                    </div>
                </div>
            </div>
        </IntroSpecificationsCarStyled>
    )
}

export default IntroSpecifications

const IntroSpecificationsCarStyled = styled.div`
margin-left: 1%;
margin-bottom: 5vh;
.oEB{
    font - family: 'Object-ExtraBold', sans-serif;
}
.grid{
    display: grid;
}

.one-columns{
    grid - template - columns: 100%;
}
.two-columns{
    grid - template - columns: 50% 50%;
}

.info-btn{
}

.d-inline-flex{
    display: inline-flex;
}
.d-inline{
    display: inline;
}

.container-red-mark{
    p{
        width: auto;       
    }
    svg{
    height: 100%;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 1vw;
    min-height: 3vh;
    width: auto;
    } 
    @media (max-width: 990px){
        p{
            width: 15vh;
        }
        svg{
            margin-right: 3vw;
        }
    }
}

.mr-1{
    margin-right: 1vw;
}
.mr-2{
    margin-right: 2vw;
}

iframe{
    height: 45vh;
    width: 100%;
}

.container-info-btns{
    align - self: center;
}
p{
    margin: ${(props) => props.mobile ? '0.5em 0 !important' : '1em 0 !important'};
}
`

